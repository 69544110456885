import {
	Alert,
	AlertTitle,
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton, Link,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import {ArrowForward, Cancel, CheckCircle, CompareArrows, Info, Language, WarningAmber} from "@mui/icons-material";
import {useState} from "react";

/**
 * @param dense {boolean}
 * @param from {string}
 * @param to {string}
 * @param faults {?Object<string, {article: string, date: string}>}
 * @param url {string}
 * @param subProductAlert {?{parentCompany: string, productList: ?string}}
 * @param comparisonData {?({functionName: string, from: {available: boolean, notes: ?string}, to: {available: boolean, notes: ?string}}[])}
 * @returns {JSX.Element}
 * @constructor
 */
function AppCard({ dense, from, to, faults, url, subProductAlert, comparisonData }) {
	const [faultsDialogOpen, setFaultsDialogOpen] = useState(false);
	const [comparisonDialogOpen, setComparisonDialogOpen] = useState(false);
	const [subProductAlertDialogOpen, setSubProductAlertDialogOpen] = useState(false);

	return (
		<>
			<div>
				<Card>
					<CardContent>
						<div style={{display: "flex", justifyContent: dense ? "space-around" : "space-evenly", alignItems: "center"}}>
							<div style={{textAlign: "center"}}>
								<img src={`/logos/${from}.png`} style={{width: 80, height: 80}} />
								<Typography variant="body1">{from}</Typography>
							</div>
							<ArrowForward sx={{fontSize: 60, color: "#424242"}} />
							<div style={{textAlign: "center"}}>
								<img src={`/logos/${to}.png`} style={{width: 80, height: 80}} />
								<Typography variant="body1">{to}</Typography>
							</div>
						</div>
						{
							subProductAlert &&
							<Alert severity="warning" style={{marginTop: 20}} action={<IconButton color="inherit" size="small" onClick={() => setSubProductAlertDialogOpen(true)}><Info /></IconButton>}>
								<AlertTitle>{from} to jeden z kilku produktów jednej firmy.</AlertTitle>
								Kliknij ikonę po prawej, aby dowiedzieć się, dlaczego to ma znaczenie.
							</Alert>
						}
					</CardContent>
					<CardActions style={dense ? {flexDirection: "column", alignItems: "flex-start"} : {}}>
						<Button
							size="small"
							variant="contained"
							startIcon={<Language />}
							onClick={() => window.open(url, "_blank")}
						>
							Strona {to}
						</Button>
						{
							dense ?
								<div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 5}}>
									{comparisonData && <Button
										size="small"
										color="secondary"
										startIcon={<CompareArrows />}
										onClick={() => setComparisonDialogOpen(true)}
									>
										Porównaj
									</Button>}
									{faults && <Button
										size="small"
										color="secondary"
										startIcon={<WarningAmber />}
										onClick={() => setFaultsDialogOpen(true)}
									>
										Przewinienia {from}
									</Button>}
								</div>
								:
								<>
									{faults && <Button
										size="small"
										color="secondary"
										startIcon={<WarningAmber />}
										onClick={() => setFaultsDialogOpen(true)}
									>
										Przewinienia {from}
									</Button>}
									{comparisonData && <Button
										size="small"
										color="secondary"
										startIcon={<CompareArrows />}
										onClick={() => setComparisonDialogOpen(true)}
									>
										Porównaj
									</Button>}
								</>
						}
					</CardActions>
				</Card>
			</div>
			{
				faults &&
					<Dialog open={faultsDialogOpen} onClose={() => setFaultsDialogOpen(false)}>
						<DialogTitle>Przewinienia {from}</DialogTitle>
						<DialogContent>
							<DialogContentText>Oto niektóre z przewinień dotyczących ochrony danych i innych ze strony {from}:</DialogContentText>
							<List>
								{
									Object.entries(faults).map(([title, {article: articleUrl, date, icon}]) => {
										const urlObj = new URL(articleUrl);
										const iconUrl = icon ?? urlObj.origin + "/favicon.ico";
										const sourceName = urlObj.host;

										return (
											<ListItemButton key={articleUrl}>
												<ListItemAvatar>
													<Avatar src={iconUrl} />
												</ListItemAvatar>
												<ListItemText primary={title} secondary={sourceName + " • " + date} onClick={() => window.open(articleUrl, "_blank")} />
											</ListItemButton>
										)
									})
								}
							</List>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setFaultsDialogOpen(false)}>Zamknij</Button>
						</DialogActions>
					</Dialog>
			}
			{
				subProductAlert &&
					<Dialog open={subProductAlertDialogOpen} onClose={() => setSubProductAlertDialogOpen(false)}>
						<DialogTitle>Jedna firma i wiele produktów. Jakie to ma znaczenie?</DialogTitle>
						<DialogContent>
							<div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
								<img src={`/logos/companies/${subProductAlert.parentCompany}.png`} style={{width: 90, height: 90}} />
								<div style={{marginLeft: 10}}>
									<Typography variant="overline" style={{lineHeight: 1}}>Właściciel aplikacji {from}</Typography>
									<Typography variant="h4" style={{fontWeight: 900}}>{subProductAlert.parentCompany}</Typography>
								</div>
							</div>
							<DialogContentText>
								Korzystając z kilku aplikacji tej samej firmy jednocześnie, może ona wykorzystywać
								informacje z Twoich kont do tworzenia <b>szczegółowego profilu</b> na Twój temat.
								Ułatwia to <b>sprzedaż danych osobowych</b> reklamodawcom lub <b>innym firmom</b>.
								{
									subProductAlert.productList &&
										<>
											<p/>
											Możesz zobaczyć pełną listę produktów firmy {subProductAlert.parentCompany} <Link href={subProductAlert.productList} target="_blank">tutaj</Link>.
										</>
								}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setSubProductAlertDialogOpen(false)}>Rozumiem</Button>
						</DialogActions>
					</Dialog>
			}
			{
				comparisonData &&
					<Dialog open={comparisonDialogOpen} onClose={() => setComparisonDialogOpen(false)} maxWidth="md" scroll="paper">
						<DialogTitle>Porównanie możliwości {from} i {to}</DialogTitle>
						<DialogContent>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>Funkcja</TableCell>
										<TableCell align="center">
											<img src={`/logos/${from}.png`} style={{width: 17, height: 17, marginRight: 10, position: "relative", top: 3}} />
											{from}
										</TableCell>
										<TableCell align="center">
											<img src={`/logos/${to}.png`} style={{width: 17, height: 17, marginRight: 10, position: "relative", top: 3}} />
											{to}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										comparisonData.map(({functionName, from: fromPlatformInfo, to: toPlatformInfo}) => {
											return (
												<TableRow key={functionName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
													<TableCell>{functionName}</TableCell>
													<TableCell align="center">
														{fromPlatformInfo.available ? <CheckCircle color="success" /> : <Cancel color="error" />}
														<span style={{position: "relative", bottom: 6, marginLeft: 3}}>{fromPlatformInfo.notes ?? ""}</span>
													</TableCell>
													<TableCell align="center">
														{toPlatformInfo.available ? <CheckCircle color="success" /> : <Cancel color="error" />}
														<span style={{position: "relative", bottom: 6, marginLeft: 3}}>{toPlatformInfo.notes ?? ""}</span>
													</TableCell>
												</TableRow>
											);
										})
									}
								</TableBody>
							</Table>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setComparisonDialogOpen(false)}>Zamknij</Button>
						</DialogActions>
					</Dialog>
			}
		</>
	);
}

export default AppCard;
