import './App.css';
import {Apps, AttachMoney, Business, Google, Help, VisibilityOff} from "@mui/icons-material";
import {
	Alert, AlertTitle,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle, Link, Snackbar, Tab, Tabs,
	Typography,
	useMediaQuery
} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import AppCard from "./AppCard";
import {useEffect, useState} from "react";
import InvestmentGiantsPage from "./InvestmentGiantsPage";
const alternativesData = require("./data.json");

const themesForTabs = {
	0: createTheme({
		palette: {
			type: 'light',
			primary: {
				main: '#1e88e5',
				light: '#42a5f5',
				dark: '#1976d2',
			},
			secondary: {
				main: '#f50057',
			},
		}
	}),
	1: createTheme({
		palette: {
			type: 'dark',
			primary: {
				main: '#d81b60',
				dark: '#d81b60',
				light: '#ec407a',
			},
			secondary: {
				main: '#eeeeee',
				light: '#fafafa',
				dark: '#bdbdbd',
			},
			background: {
				default: '#080808',
				paper: '#171717',
			},
			success: {
				main: '#7cb342',
				light: '#8bc34a',
				dark: '#2e7d32',
			},
			text: {
				primary: '#ffffff',
				secondary: 'rgba(255,255,255,0.7)',
				disabled: 'rgba(255,255,255,0.5)',
				hint: 'rgba(255,255,255,0.5)',
			},
			divider: 'rgba(255,255,255,0.12)'
		}
	})
};

const cssVariablesForTabs = {
	0: {
		"--header-bg": "#E0E0E0",
		"--app-bg": "#F5F5F5",
		"--fg": "auto"
	},
	1: {
		"--header-bg": "black",
		"--app-bg": "#0b0b0b",
		"--fg": "white"
	}
};

function App() {
	const [whyItsImportantDialogOpen, setWhyItsImportantDialogOpen] = useState(false);
	const [aboutGoogleDialogOpen, setAboutGoogleDialogOpen] = useState(false);
	const [smallScreenAlertShown, setSmallScreenAlertShown] = useState(false);
	const [whatIsAStakeDialogOpen, setWhatIsAStakeDialogOpen] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);

	const isSmallScreen = useMediaQuery("screen and (max-width: 520px)");

	useEffect(() => {
		if (isSmallScreen) setSmallScreenAlertShown(true);
	}, [isSmallScreen]);

	return (
		<ThemeProvider theme={themesForTabs[currentTab]}>
			<div className="App" style={cssVariablesForTabs[currentTab]}>
				<div>
					{
						currentTab === 0 &&
							<>
								<VisibilityOff sx={{fontSize: 200}} />
								<Typography variant="h1" style={{textAlign: "center", margin: "0 25px"}}>
									Uwolnij się od śledzenia
								</Typography>
								<Typography variant="h5" style={{textAlign: "center", margin: "0 25px"}}>
									Oto alternatywy dla popularnych aplikacji, w których to ty jesteś produktem.
								</Typography>
								<div className="HeaderButtons">
									<Button color="secondary" size="large" variant="outlined" startIcon={<Help />} onClick={() => setWhyItsImportantDialogOpen(true)}>
										Dlaczego to ważne
									</Button>
									<Button color="secondary" size="large" variant="outlined" startIcon={<Google />} onClick={() => setAboutGoogleDialogOpen(true)}>
										O tożsamości Google
									</Button>
								</div>
							</>
					}
					{
						currentTab === 1 &&
							<>
								<AttachMoney sx={{fontSize: 200}} />
								<Typography variant="h1" style={{textAlign: "center", margin: "0 25px"}}>
									Pieniądze grają dużą rolę
								</Typography>
								<Typography variant="h5" style={{textAlign: "center", margin: "0 25px"}}>
									Tu dowiesz się o największych inwestorach, którzy posiadają swoje udziały w wielu firmach.
								</Typography>
								<div className="HeaderButtons">
									<Button color="secondary" size="large" variant="outlined" startIcon={<Help />} onClick={() => setWhatIsAStakeDialogOpen(true)}>
										Czym jest udział w biznesie
									</Button>
								</div>
							</>
					}
					<Tabs value={currentTab} onChange={(_, newValue) => setCurrentTab(newValue)} centered>
						<Tab label="Alternatywne aplikacje" icon={<Apps />} iconPosition="top" />
						<Tab label="Giganci inwestycyjni" icon={<Business />} iconPosition="top" />
					</Tabs>
				</div>
				{
					currentTab === 0 &&
						<div className="AppListGrid">
							{alternativesData.map(data => <AppCard dense={isSmallScreen} {...data} />)}
						</div>
				}
				{
					currentTab === 1 &&
						<InvestmentGiantsPage />
				}
				<Dialog open={aboutGoogleDialogOpen} onClose={() => setAboutGoogleDialogOpen(false)} maxWidth="sm">
					<DialogTitle>O firmie Google</DialogTitle>
					<DialogContent>
						<DialogContentText>
							To prawda, że Google jest firmą mającą pod kontrolą <Link href="https://about.google/intl/ALL_us/products/#all-products" target="_blank">wiele produktów</Link>, ale czy wiesz, że samo
							Google posiada swojego właściciela? Jest nim firma <Link href="https://en.wikipedia.org/wiki/Alphabet_Inc." target="_blank">Alphabet</Link>.
						</DialogContentText>
						<div style={{display: "flex", alignItems: "center", flexDirection: "column", margin: 20}}>
							<img id="Alphabet-logo" src="/logos/companies/Alphabet.png" />
							<Typography variant="caption" style={{textAlign: "center"}}>Logo firmy Alphabet - właściciela Google</Typography>
						</div>
						<DialogContentText>
							Na początku, to Google miało być właścicielem Alphabet, jednakże w wyniku procesu
							restrukturyzacji zakończonym 2 października 2015 roku role zostały odwrócone. Teraz, Alphabet
							posiada pod sobą również takie firmy jak <Link href="https://en.wikipedia.org/wiki/Verily" target="_blank">Verily</Link>,&nbsp;
							<Link href="https://en.wikipedia.org/wiki/Waymo" target="_blank">Waymo</Link>,&nbsp;
							<Link href="https://en.wikipedia.org/wiki/Wing_(company)" target="_blank">Wing</Link> czy&nbsp;
							<Link href="https://en.wikipedia.org/wiki/DeepMind" target="_blank">DeepMind</Link>.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setAboutGoogleDialogOpen(false)}>Zamknij</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={whyItsImportantDialogOpen} onClose={() => setWhyItsImportantDialogOpen(false)}>
					<DialogTitle>Dlaczego brak prywatności jest szkodliwy</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Jeżeli korzystasz z serwisów, które zbierają <b>dane o użytkowaniu</b> takie jak czas spędzony w
							aplikacji, co w niej przeglądasz, prywatne rozmowy itp., to ich właściciele mogą używać
							algorytmów, aby <b>dopasowywać treści (w tym reklamy)</b> pod twoje zainteresowania. W takiej
							formie nie brzmi to jak coś złego, ale nie jest to pełen obraz tego, co dzieje się za kulisami
							kolorowych aplikacji. W rzeczywistości zgoda na poświęcenie Twoich danych może spowodować
							utworzenie <b>"bańki" informacyjnej</b> wokół użytkownika co z kolei wpłynie na <b>podejmowane
							przez Ciebie decyzje</b> - od wyboru restauracji, z której dzisiaj zamówisz jedzenie, po wybór
							kandydatów do Sejmu i Senatu w następnych wyborach parlamentarnych. Wszystko zaczyna wyglądać
							jeszcze gorzej, kiedy zorientujesz się, że firmy wymieniają się między sobą profilami
							użytkowników do tzw. <b>targeted advertising</b>, co poszerza efekt działania takiej bańki i
							zwiększa ilość miejsc w internecie, w których może się ona rozwijać. Dodatkowo, techniki
							stosowane w ramach <b>braku poszanowania dla Twoich danych</b> (np. brak szyfrowania czy
							centralizacja) sprawiają, że są one o wiele bardziej <b>wrażliwe na wycieki</b>.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setWhyItsImportantDialogOpen(false)}>Zamknij</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={whatIsAStakeDialogOpen} onClose={() => setWhatIsAStakeDialogOpen(false)}>
					<DialogTitle>Czym jest udział w biznesie</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Udział w biznesie to ogólny termin odnoszący się do <b>własności lub odpowiedzialności</b> za
							firmę lub organizację. Istnieje wiele sposobów, dzięki którym możesz mieć udział w firmie, w
							tym <b>bycie częściowym właścicielem</b>.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setWhatIsAStakeDialogOpen(false)}>Zamknij</Button>
					</DialogActions>
				</Dialog>
				<Snackbar open={smallScreenAlertShown} autoHideDuration={6000} onClose={() => setSmallScreenAlertShown(false)}>
					<Alert variant="filled" onClose={() => setSmallScreenAlertShown(false)} severity="info" sx={{width: "100%"}}>
						<AlertTitle>Jesteś na telefonie?</AlertTitle>
						Przeglądanie strony będzie łatwiejsze w orientacji poziomej.
					</Alert>
				</Snackbar>
			</div>
		</ThemeProvider>
	);
}

export default App;
