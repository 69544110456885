import {
	Accordion,
	AccordionDetails,
	AccordionSummary, Button, Divider, Link, Paper,
	Typography
} from "@mui/material";
import {AttachMoney, ExpandMore, Person} from "@mui/icons-material";

/**
 * @param filledPercentage {number}
 * @param filledColor {string}
 * @returns {string}
 */
function generatePieChartGradient(filledPercentage, filledColor) {
	const percentageAsDegrees = Math.floor(filledPercentage * 3.6);

	return `conic-gradient(${filledColor} ${percentageAsDegrees}deg, transparent 0)`;
}

function CompanyInfoField({ icon, title, value }) {
	return (
		<Paper className="CompanyInfoField" elevation={3}>
			{icon}
			<div className="CompanyInfoField-right">
				<Typography variant="overline">{title}</Typography>
				<Typography variant="body1">{value}</Typography>
			</div>
		</Paper>
	);
}

/**
 * @param name {string}
 * @param description
 * @param logo {string}
 * @param fields {{icon: any, title: string, value: string}[]}
 * @param wikipediaUrl {string}
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function CompanyInfo({ name, description, logo, fields = [], wikipediaUrl, children }) {
	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMore color="secondary" />}>
				<Typography>{name}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<div className="CompanyInfo">
					<Typography>{description} <Link href={wikipediaUrl} target="_blank">Wikipedia</Link></Typography>
					<div style={{flex: "0 0 40px"}}>{/* Spacing */}</div>
					<img src={logo} />
				</div>
				<div className="CompanyInfo-fields">
					{fields.map(field => <CompanyInfoField {...field} />)}
				</div>
				<br/>
				{children}
			</AccordionDetails>
		</Accordion>
	);
}

/**
 * @param companyName {string}
 * @param companyLogo {string}
 * @param invertMode {"none"|"normal"|"monochrome"}
 * @param ownershipStakePercentage
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function OwnershipStakeInfo({ companyName, companyLogo, invertMode = "none", ownershipStakePercentage, children }) {
	return (
		<div>
			<Paper className="OwnershipStakeInfo" variant="outlined">
				<div className="OwnershipStakeInfo-header">
					<div className="OwnershipStakeInfo-logoAndName">
						<img src={companyLogo} data-inverted={invertMode === "normal" ? "1" : "0"} data-monochrome-inverted={invertMode === "monochrome" ? "1" : "0"} />
						<Typography variant="h5" sx={{fontWeight: 900}}>{companyName}</Typography>
					</div>
					<div className="OwnershipStakeInfo-pieChart" style={{backgroundImage: generatePieChartGradient(ownershipStakePercentage, "#d81b60")}}>
						<Typography variant="button" sx={{fontSize: 20}}>
							{ownershipStakePercentage > -1 ? `${ownershipStakePercentage}%` : "?"}
						</Typography>
						<Typography variant="button" sx={{fontSize: 10}}>udziałów</Typography>
					</div>
				</div>
				<Divider style={{marginTop: 10, marginBottom: 10}} />
				{children}
			</Paper>
		</div>
	);
}

function InvestmentGiantsPage() {
	return (
		<div className="InvestmentGiantsPage">
			<Typography variant="body1" style={{marginBottom: 10}}>Kliknij na nazwę firmy, aby dowiedzieć się więcej.</Typography>
			<CompanyInfo
				name="Tencent Holdings Ltd."
				description={<>
					<b>Tencent Holdings Ltd.</b> to chiński międzynarodowy konglomerat technologiczno-rozrywkowy i
					spółka holdingowa z siedzibą w Shenzhen. Jest to jedna z najbardziej dochodowych firm
					multimedialnych na świecie pod względem przychodów. Jest to również największa na świecie firma
					w branży gier wideo w oparciu o swoje inwestycje, a Tencent Games jest pododdziałem Tencent
					Interactive Entertainment Group (IEG) koncentrującym się na wydawaniu gier.
				</>}
				logo="https://upload.wikimedia.org/wikipedia/commons/2/22/Tencent_Logo.svg"
				wikipediaUrl="https://en.wikipedia.org/wiki/Tencent"
				fields={[
					{
						icon: <AttachMoney sx={{fontSize: 50}} />,
						title: "Przychód",
						value: "393 104 474 589,84 PLN"
					},
					{
						icon: <Person sx={{fontSize: 50}} />,
						title: "Ilość pracowników",
						value: "112 771"
					}
				]}
			>
				<Typography variant="h3">Wybrane udziały własnościowe</Typography>
				<br/>
				<div className="InvestmentGiantsPage-ownershipStakesGrid">
					<OwnershipStakeInfo
						companyName="1C Company"
						companyLogo="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/1C_Company_logo.svg/1920px-1C_Company_logo.svg.png"
						ownershipStakePercentage={100}
					>
						<Typography>Najpopularniejszy rosyjski dystrybutor dla wydawców takich jak:</Typography>
						<div className="LogosDisplay">
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/commons/6/6e/Activision_Blizzard_logo.svg" style={{filter: "invert()"}} />
								<Typography variant="caption">Activision Blizzard</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/commons/2/22/Bethesda_Game_Studios_logo.svg" style={{filter: "invert()"}} />
								<Typography variant="caption">Bethesda Game Studios</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/commons/d/d6/Take-Two_Interactive_Logo.svg" style={{filter: "invert()"}} />
								<Typography variant="caption">Take-Two Interactive</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/commons/5/53/Warner_Bros_Interactive_Entertainment_2019.png" />
								<Typography variant="caption">Warner Bros.<br/>Interactive<br/>Entertainment</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Ubisoft_logo.svg" style={{filter: "invert()"}} />
								<Typography variant="caption">Ubisoft</Typography>
							</div>
						</div>
					</OwnershipStakeInfo>
					<OwnershipStakeInfo
						companyName="Riot Games"
						companyLogo="https://upload.wikimedia.org/wikipedia/en/thumb/5/5b/Riot_Games_2022.svg/1920px-Riot_Games_2022.svg.png"
						ownershipStakePercentage={100}
					>
						<Typography>Najbardziej znane z gier:</Typography>
						<div className="LogosDisplay">
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/commons/d/d8/League_of_Legends_2019_vector.svg" />
								<Typography variant="caption">League of Legends</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/commons/6/64/League_of_Legends_Wild_Rift_logo.svg" />
								<Typography variant="caption">League of Legends: Wild Rift</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/b/b0/Legends_of_Runeterra_logo.png" />
								<Typography variant="caption">Legends of Runeterra</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/commons/f/fc/Valorant_logo_-_pink_color_version.svg" />
								<Typography variant="caption">Valorant</Typography>
							</div>
						</div>
					</OwnershipStakeInfo>
					<OwnershipStakeInfo
						companyName="Turtle Rock Studios"
						companyLogo="https://upload.wikimedia.org/wikipedia/en/9/90/Turtle_Rock_Studios_logo.png"
						invertMode="normal"
						ownershipStakePercentage={100}
					>
						<Typography>Znane z pracy nad takimi grami jak:</Typography>
						<div className="LogosDisplay" data-bigger="1">
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/5/5b/Left4Dead_Windows_cover.jpg" />
								<Typography variant="caption">Left 4 Dead</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/b/ba/Left4Dead2.jpg" />
								<Typography variant="caption">Left 4 Dead 2</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/a/a8/CZbox.jpg" />
								<Typography variant="caption">Counter-Strike:<br/>Condition Zero</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/4/43/Counter-Strike_Source_%28box_art%29.jpg" />
								<Typography variant="caption">Counter-Strike: Source</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/4/44/Half-Life_2_Deathmatch_cover.jpg" />
								<Typography variant="caption">Half-Life 2: Deathmatch</Typography>
							</div>
						</div>
					</OwnershipStakeInfo>
					<OwnershipStakeInfo
						companyName="Digital Extremes"
						companyLogo="https://upload.wikimedia.org/wikipedia/en/2/2a/Digital_Extremes_Logo.jpg"
						ownershipStakePercentage={97}
					>
						<Typography>Najbardziej znane z takich gier jak:</Typography>
						<div className="LogosDisplay" data-bigger="1">
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/f/f4/Unrealtournament.jpg" />
								<Typography variant="caption">Unreal Tournament</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/6/6d/BioShock_cover.jpg" />
								<Typography variant="caption">BioShock</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/b/bd/Warframe_Cover_Art.png" />
								<Typography variant="caption">Warframe</Typography>
							</div>
						</div>
					</OwnershipStakeInfo>
					<OwnershipStakeInfo
						companyName="Supercell"
						companyLogo="https://upload.wikimedia.org/wikipedia/commons/5/5a/Supercell-logo.svg"
						invertMode="normal"
						ownershipStakePercentage={84}
					>
						<Typography>Najbardziej znane z takich gier mobilnych jak:</Typography>
						<div className="LogosDisplay">
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/5/59/Clash_of_Clans_Logo.png" />
								<Typography variant="caption">Clash of Clans</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/b/b8/Clash_Royale_game_logo.png" />
								<Typography variant="caption">Clash Royale</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/1/18/Brawl_Stars_logo.png" />
								<Typography variant="caption">Brawl Stars</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/9/92/Hay_Day_logo.png" />
								<Typography variant="caption">Hay Day</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/9/9f/Boom_Beach_logo.png" />
								<Typography variant="caption">Boom Beach</Typography>
							</div>
						</div>
					</OwnershipStakeInfo>
					<OwnershipStakeInfo
						companyName="Epic Games"
						companyLogo="https://upload.wikimedia.org/wikipedia/commons/3/31/Epic_Games_logo.svg"
						invertMode="normal"
						ownershipStakePercentage={40}
					>
						<Typography>Najbardziej znane z tych gier:</Typography>
						<div className="LogosDisplay">
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/commons/0/0e/FortniteLogo.svg" style={{filter: "invert()"}} />
								<Typography variant="caption">Fortnite</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/5/5c/Unreal_Tournament.png" />
								<Typography variant="caption">Unreal Tournament</Typography>
							</div>
						</div>
						<Typography>Oprócz gier, firma wyprodukowała także te produkty:</Typography>
						<div className="LogosDisplay">
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/commons/2/20/UE_Logo_Black_Centered.svg" style={{filter: "invert()"}} />
								<Typography variant="caption">Unreal Engine</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/commons/5/57/Epic_games_store_logo.svg" />
								<Typography variant="caption">Epic Games Store</Typography>
							</div>
						</div>
					</OwnershipStakeInfo>
					<OwnershipStakeInfo
						companyName="Don't Nod"
						companyLogo="https://upload.wikimedia.org/wikipedia/en/6/68/Don%27t_Nod_Logo_2022.svg"
						invertMode="monochrome"
						ownershipStakePercentage={22.63}
					>
						<Typography>Najbardziej znane z gier:</Typography>
						<div className="LogosDisplay" data-bigger="1">
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/0/0d/Life_Is_Strange_cover_art.png" />
								<Typography variant="caption">Life Is Strange</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/f/fa/Life_Is_Strange_2_cover.jpg" />
								<Typography variant="caption">Life Is Strange 2</Typography>
							</div>
						</div>
					</OwnershipStakeInfo>
					<OwnershipStakeInfo
						companyName="FromSoftware"
						companyLogo="https://upload.wikimedia.org/wikipedia/commons/0/00/Fromsoftware_logo.svg"
						invertMode="normal"
						ownershipStakePercentage={16.25}
					>
						<Typography>Najbardziej znane z gier:</Typography>
						<div className="LogosDisplay" data-bigger="1">
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/8/8d/Dark_Souls_Cover_Art.jpg" />
								<Typography variant="caption">Dark Souls</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/e/ed/Dark_Souls_II_cover.jpg" />
								<Typography variant="caption">Dark Souls II</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/b/bb/Dark_souls_3_cover_art.jpg" />
								<Typography variant="caption">Dark Souls III</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/6/68/Bloodborne_Cover_Wallpaper.jpg" />
								<Typography variant="caption">Bloodborne</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/b/b9/Elden_Ring_Box_art.jpg" />
								<Typography variant="caption">Elden Ring</Typography>
							</div>
						</div>
					</OwnershipStakeInfo>
					<OwnershipStakeInfo
						companyName="Krafton (Bluehole Studio)"
						companyLogo="https://upload.wikimedia.org/wikipedia/commons/1/14/Krafton_Logo.svg"
						invertMode="normal"
						ownershipStakePercentage={13.6}
					>
						<Typography>Najbardziej znane z gier:</Typography>
						<div className="LogosDisplay" data-bigger="1">
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/9/9f/Pubgbattlegrounds.png" />
								<Typography variant="caption">PUBG: Battlegrounds</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/4/44/PlayerUnknown%27s_Battlegrounds_Mobile.webp" />
								<Typography variant="caption">PUBG Mobile</Typography>
							</div>
						</div>
					</OwnershipStakeInfo>
					<OwnershipStakeInfo
						companyName="Ubisoft"
						companyLogo="https://upload.wikimedia.org/wikipedia/commons/7/78/Ubisoft_logo.svg"
						invertMode="normal"
						ownershipStakePercentage={9.99}
					>
						<Typography>Najbardziej znane z gier:</Typography>
						<div className="LogosDisplay" data-bigger="1">
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/5/52/Assassin%27s_Creed.jpg" />
								<Typography variant="caption">Assassin's Creed</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/d/d5/For_Honor_cover_art.jpg" />
								<Typography variant="caption">For Honor</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/commons/a/ad/Prince_of_Persia_1_-_Macintosh_-_Starting_screen.png" />
								<Typography variant="caption">Prince of Persia</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/d/d9/Watch_Dogs_box_art.jpg" />
								<Typography variant="caption">Watch Dogs</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/a/a5/Far_Cry_1_boxshot.jpg" />
								<Typography variant="caption">Far Cry</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/5/50/Just_Dance_%28Wii%29_boxart.jpg" />
								<Typography variant="caption">Just Dance</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/4/45/Rayman_1_cover.png" />
								<Typography variant="caption">Rayman</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/3/31/Tom_Clancy%27s_logo.jpg" />
								<Typography variant="caption">Gry z serii<br/>Tom Clancy's</Typography>
							</div>
						</div>
					</OwnershipStakeInfo>
					<OwnershipStakeInfo
						companyName="Paradox Interactive"
						companyLogo="https://upload.wikimedia.org/wikipedia/en/d/d0/Paradox_Interactive_logo.svg"
						invertMode="normal"
						ownershipStakePercentage={5}
					>
						<Typography>Najbardziej znane z gier:</Typography>
						<div className="LogosDisplay" data-bigger="1">
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/b/b3/Hearts_of_Iron_IV_packshot.jpg" />
								<Typography variant="caption">Hearts of Iron IV</Typography>
							</div>
							<div>
								<img src="https://upload.wikimedia.org/wikipedia/en/e/e7/Stellaris_cover_art.jpg" />
								<Typography variant="caption">Stellaris</Typography>
							</div>
						</div>
					</OwnershipStakeInfo>
					<OwnershipStakeInfo
						companyName="Discord"
						companyLogo="https://upload.wikimedia.org/wikipedia/en/9/98/Discord_logo.svg"
						ownershipStakePercentage={-1}
					>
						<Typography>
							Discord to platforma społecznościowa VoIP i komunikatorów. Niestety procent udziałów jest
							nieznany. Wiadomo jedynie, że Tencent jest jednym z inwestorów.
						</Typography>
					</OwnershipStakeInfo>
					<OwnershipStakeInfo
						companyName="Roblox Corporation"
						companyLogo="https://upload.wikimedia.org/wikipedia/commons/4/4b/Roblox_Logo_2022.svg"
						invertMode="normal"
						ownershipStakePercentage={-1}
					>
						<Typography>
							Roblox to platforma gier online i system tworzenia gier opracowany przez Roblox Corporation,
							który pozwala użytkownikom programować gry i grać w gry stworzone przez innych użytkowników.
							Niestety procent udziałów jest nieznany. Wiadomo jedynie, że Tencent jest jednym z
							inwestorów.
						</Typography>
					</OwnershipStakeInfo>
				</div>
				<Typography variant="h3" align="center">+ 32 <Link href="https://en.wikipedia.org/wiki/Tencent#Foreign_investments" target="_blank">inne</Link></Typography>
			</CompanyInfo>
			<br/>
			Wkrótce pojawi się tutaj więcej treści.
		</div>
	);
}

export default InvestmentGiantsPage;
